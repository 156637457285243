<template>
  <div id="goods-list">
    <div class="title">
      <div class="left">
        <div :class="index === 0 ? 'opt-in' : ''" @click="sorting(0)">
          综合排序
        </div>
        <div
          class="sales"
          :class="index === 1 ? 'opt-in' : ''"
          @click="sorting(1)"
        >
          <p>销量</p>
          <span
            ><i class="top" :class="sales === 1 ? 'onTop' : ''"></i
            ><i class="bottom" :class="sales === 2 ? 'onBottom' : ''"></i
          ></span>
        </div>
        <div
          class="price"
          :class="index === 2 ? 'opt-in' : ''"
          @click="sorting(2)"
        >
          <p>价格</p>
          <span
            ><i class="top" :class="price === 1 ? 'onTop' : ''"></i
            ><i class="bottom" :class="price === 2 ? 'onBottom' : ''"></i
          ></span>
        </div>
      </div>
      <div class="right">
        共<span>{{ total }}</span
        >件相关商品
      </div>
    </div>

    <div
      class="goods"
      :style="Items.length === 0 ? 'display:block' : 'display:flex'"
      v-loading="loading"
    >
      <goods v-for="(list, index) in Items" :key="index" :information="list" />
      <el-empty description="暂无商品" v-show="Items.length === 0"></el-empty>
    </div>

    <el-pagination
      :page-size="$route.name === 'store-category' ? 8 : 25"
      :pager-count="9"
      layout="prev, pager, next"
      :total="total"
      @prev-click="prev"
      @next-click="next"
      @current-change="current"
      :current-page="pageNum"
    >
    </el-pagination>
  </div>
</template>

<script>
import Goods from "@/components/Goods";
export default {
  name: "goods-list",
  components: { Goods },
  props: ["category", "brandId", "time", "city", "categoryId", "like"],
  data() {
    return {
      Items: "",
      pageNum: 1, //页码
      total: 0,
      // order: "desc",//test
      order: "",
      index: 0,
      sales: 0, //销量排序
      price: 0, //价格排序
      timeOrder: "", //时间排序
      sortColumn: "",
      loading: false,
      storeCategoryJudge: 0, //企业类目的判断
    };
  },
  watch: {
    //价格
    price: {
      handler: function (val, oldVal) {
        if (val === 1) {
          this.order = "desc";
          this.judge();
        } else if (val === 2) {
          this.order = "asc";
          this.judge();
        }
      },
      deep: true,
    },
    //销量
    sales: {
      handler: function (val, oldVal) {
        // console.log(val);
      },
      deep: true,
    },
    //企业类目
    categoryId: {
      handler: function (val, oldVal) {
        this.storeCategoryJudge = 2;
        this.judge();
      },
      deep: true,
    },
    //企业搜索框
    like: {
      handler: function (val, oldVal) {
        if (val != undefined) {
          this.storeCategoryJudge = 1;
          this.judge();
        }
      },
      deep: true,
      immediate: true,

    },
    //品牌id
    brandId: {
      handler: function (val, oldVal) {
        this.judge();
        this.pageNum = 1;
      },
      deep: true,
    },
    city: {
      handler: function (val, oldVal) {
        this.judge();
        this.pageNum = 1;
      },
      deep: true,
    },
    //时间
    time: {
      handler: function (val, oldVal) {
        if (val != "") {
          this.timeOrder = "-" + val;
          this.judge();
        } else {
          this.timeOrder = val;
          this.judge();
        }
        this.pageNum = 1;
      },
      deep: true,
    },
    index: {
      handler: function (val, oldVal) {
        if (val === 0) {
          this.order = "";
          // this.order = "desc";
          this.judge();
        }
      },
      deep: true,
    },
    $route: {
      handler: function (val, oldVal) {
        this.pageNum = 1;
        this.judge();
      },
    },
  },
  created() {
    // console.log(this.$route.name);
    this.listenerFunction();
    this.judge();
    // console.log(this.$route.name);
  },
  beforeDestroy() {
    document.removeEventListener("scroll", this.listenerFunction);
    this.pageNum = 1;
    clearTimeout(this.Time);
  },

  methods: {
    // 获取店铺首页所有商品
    async getStore() {
      const data = await this.$API.store.getStoreHomePage({
        object: {
          storeId: this.$route.params.id,
          order: this.order,
          // 排序 desc/asc   批发价
          sortColumn: this.sortColumn,
        },
        pageNum: this.pageNum,
        pageSize: 25,
      });
      this.Items = data.data.data.storeHomePageItems.map((a) => {
        return {
          ...a,
          filePath: a.filePath.split(",")[0],
        };
      });
      this.total = data.data.data.total;
    },
    //已发布商品列表
    async getAllGoods() {
      const data = await this.$API.homePage.CommList({
        object: {
          brandId: this.brandId,
          ceCategoryId: null,
          location: "",
          order: this.order,
          // order: "desc",
          // 排序 desc/asc   批发价
          queryCriteria: this.timeOrder,
          sortColumn: this.sortColumn,
          productionPlace: this.city,
          like: this.$route.query.like,
        },
        pageNum: this.pageNum,
        pageSize: 25,
      });
      this.Items = data.data.data.map((a) => {
        return {
          ...a,
          filePath: a.filePath.split(",")[0],
        };
      });
      this.total = data.data.total;
    },
    //推荐品牌所有商品 && 品牌所有商品
    async getBrandAllGoods() {
      const data = await this.$API.homePage.CommList({
        object: {
          brandId: this.$route.params.brandId,
          ceCategoryId: null,
          location: "",
          order: this.order,
          //desc/asc
          queryCriteria: this.timeOrder,
          sortColumn: this.sortColumn,
        },
        pageNum: this.pageNum,
        pageSize: 25,
      });
      // console.log(data.data.data);
      this.Items = data.data.data.map((a) => {
        return {
          ...a,
          filePath: a.filePath.split(",")[0],
        };
      });
      this.total = data.data.total;
    },
    //类目商品
    async getCategoryGoods() {
      const data = await this.$API.homePage.CommList({
        object: {
          brandId: this.brandId,
          ceCategoryId: this.category,
          location: "",
          order: this.order,
          queryCriteria: this.timeOrder,
          // 排序 desc/asc   批发价
          sortColumn: this.sortColumn,
          productionPlace: this.city,
        },
        pageNum: this.pageNum,
        pageSize: 25,
      });
      this.Items = data.data.data.map((a) => {
        return {
          ...a,
          filePath: a.filePath.split(",")[0],
        };
      });
      this.total = data.data.total;
    },
    //企业类目商品
    async getStoreCategoryGoods() {
      if (this.storeCategoryJudge === 1) {
        const data = await this.$API.store.getStoreHomePage({
          object: {
            like: this.like,
            location: "",
            order: this.order,
            sortColumn: this.sortColumn,
            storeId: this.$route.params.id,
          },
          pageNum: this.pageNum,
          pageSize: 8,
        });

        // console.log(data.data.data.storeHomePageItems);
        // console.log(data.data.data.total);
        this.total = data.data.data.total;
        this.Items = data.data.data.storeHomePageItems.map((a) => {
          return {
            ...a,
            filePath: a.filePath.split(",")[0],
          };
        });
      } else if (this.storeCategoryJudge === 2) {
        const data = await this.$API.homePage.CommList({
          object: {
            brandId: "",
            enCategoryId: this.categoryId,
            location: "",
            order: this.order,
            productionPlace: "",
            queryCriteria: 0,
            sortColumn: this.sortColumn,
          },
          pageNum: this.pageNum,
          pageSize: 8,
        });
        this.Items = data.data.data.map((a) => {
          return {
            ...a,
            filePath: a.filePath.split(",")[0],
          };
        });
        this.total = data.data.total;
      } else {
        const data = await this.$API.store.getStoreHomePage({
          object: {
            location: "",
            order: this.order,
            sortColumn: this.sortColumn,
            storeId: this.$route.params.id,
          },
          pageNum: this.pageNum,
          pageSize: 8,
        });

        this.total = data.data.data.total;
        this.Items = data.data.data.storeHomePageItems.map((a) => {
          return {
            ...a,
            filePath: a.filePath.split(",")[0],
          };
        });
      }
    },

    //上一页
    prev() {
      this.pageNum--;
      this.judge();
      this.backTop();
    },
    //下一页
    next() {
      this.pageNum++;
      this.judge();
      this.backTop();
    },
    //分页数字
    current(i) {
      this.pageNum = i;
      this.judge();
      this.backTop();
    },

    listenerFunction(e) {
      document.addEventListener("scroll", this.handleScroll, true);
    },
    backTop() {
      //点击回顶部
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    },

    sorting(i) {
      this.index = i;

      if (i === 0) {
        this.sales = 0;
        this.price = 0;
        this.sortColumn = "";
      } else if (i === 1) {
        this.price = 0;
        if (this.sales === 1) {
          this.sales = 2;
        } else if (this.sales === 2) {
          this.sales = 1;
        } else {
          this.sales = 1;
        }
      } else if (i === 2) {
        this.sales = 0;
        this.sortColumn = "tradePrice";
        if (this.price === 1) {
          this.price = 2;
        } else if (this.price === 2) {
          this.price = 1;
        } else {
          this.price = 1;
        }
      }
    },
    judge() {
      if (!this.loading) {
        this.loading = true;
        this.Time = setTimeout(() => {
          this.loading = false;
          if (this.$route.name === "home-page") {
            this.getStore();
          } else if (this.$route.name === "all-goods") {
            this.getAllGoods();
          } else if (this.$route.name === "recommended-brand") {
            this.getBrandAllGoods();
          } else if (this.$route.name === "brand-goods") {
            this.getBrandAllGoods();
          } else if (this.$route.name === "category-list") {
            this.getCategoryGoods();
          } else if (this.$route.name === "store-category") {
            this.getStoreCategoryGoods();
          }
        }, 1000);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#goods-list {
  width: 1200px;
  display: flex;
  flex-direction: column;
  .title {
    width: 100%;
    height: 40px;
    background: #f2f4f9;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    border: 1px solid #eee;

    .left {
      display: flex;
      align-items: center;
      div {
        width: 99px;

        height: 39px;
        // background: #ffffff;
        opacity: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        -moz-user-select: none;
        -ms-user-select: none;
        -webkit-user-select: none;
        user-select: none;
      }
      .opt-in {
        background: #00aeeb;
        color: #fff;
      }
      .sales,
      .price {
        span {
          display: flex;
          flex-direction: column;
          margin-left: 4px;
          i {
            width: 8px;
            height: 8px;
          }
          .onTop {
            border-bottom: 5px solid #fff !important;
          }
          .onBottom {
            border-top: 5px solid #fff !important;
          }
          .top {
            // background: #ccc;
            border-bottom: 5px solid rgba($color: #ccc, $alpha: 0.7);
            border-top: 5px solid transparent;
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
            margin-bottom: 1px;
          }
          .bottom {
            border-bottom: 5px solid transparent;
            border-top: 5px solid rgba($color: #ccc, $alpha: 0.7);
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
            margin-top: 1px;
          }
        }
      }
    }
    .right {
      font-size: 14px;
      color: #999999;
      margin-right: 12px;
      span {
        color: #00aeeb;
      }
    }
  }
  .goods {
    width: 100%;
    display: flex;
    border: 1px solid #e8e8e8;
    border-top: none;
    flex-wrap: wrap;
    background: #fff;
    .goods-box {
      border-right: 1px solid #e8e8e8;
      border-bottom: 1px solid #e8e8e8;
    }
    .goods-box:nth-child(5n) {
      border-right: none;
      width: 238px;
    }
  }
  .el-pagination {
    display: flex;
    justify-content: center;
    margin: 24px 0 40px 0;
    ::v-deep button:hover {
      color: #00aeeb;
    }
    ::v-deep button {
      width: 34px;
      height: 34px;
      border: 1px solid #ddd;
      padding: 0;
      min-width: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 4px;
      i {
        margin-left: 1px;
      }
    }
    ::v-deep .el-pager {
      li {
        width: 34px;
        height: 34px;
        padding: 0;
        border: 1px solid #ddd;
        min-width: 0;
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #333333;
        line-height: 32px;
        margin: 0 4px;
      }
      li.active {
        color: #00aeeb;
        border-color: #00aeeb;
      }
      .more {
        border: none;
        margin: 0;
        width: 26px;
      }
    }
  }
}
</style>